<template>
  <div class="detail">
    <div class="detail-con">
      <!-- 返回按钮 -->
      <div @click="back()" class="detail-back flex justify-center align-center">
        <img
          src="../../../assets/img/layout/back.png"
          class="detail-back-img"
        />
        <div class="detail-back-text">
          返回
        </div>
      </div>
      <div>第{{ pageIndex + 1 }}页</div>
      <!-- 表格 -->
      <div class="detail-table">
        <div class="detail-table-con">
          <div class="detail-table-content">
            <div class="detail-table-content-title">
              <div>水样编号：{{ currentData.waterCode }}</div>
              <div>水样类型：{{ currentData.sampleTypeName }}</div>
              <div>采样日期：{{ sampleDate }}</div>
              <div>采样人员姓名：{{ sampleName }}</div>
              <div class="titleselect">
                显示 ：
                <a-checkbox-group @change="onChange"
                :default-value="qualified_arr">
                  <a-row style="display: flex;align-items: center">
                    <a-col>
                      <a-checkbox :value="qualified_arr[0]" style="font-size: 18px;">
                        达标
                      </a-checkbox>
                    </a-col>
                    <a-col>
                      <a-checkbox :value="qualified_arr[1]" style="font-size: 18px;">
                        不达标
                      </a-checkbox>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </div>
                
            </div>
            <div class="detail-table-content-top flex align-center ">
              <div
                class="detail-table-content-itemA width300 flex align-center justify-center"
                v-for="(item, index) in columns"
                :key="index"
              >
                <div class="detail-table-content-itemA-con">
                  {{ item.title }}
                </div>
              </div>
            </div>
            <div
              class="detail-table-content-list flex align-center"
              v-for="(item, index) in list"
              :key="index"
              @click="toDetail()"
            >
              <div class="detail-table-content-list-item width300">
                {{ index + 1 }}
              </div>
              <div class="detail-table-content-list-item width300">
                {{ item.detectName }} {{ item.unit ? "/ " + item.unit : "" }}
              </div>
              <div class="detail-table-content-list-item width300">
                {{ item.limitValue }}
              </div>
              <div class="detail-table-content-list-item width300">
                {{ item.qualified == 0 ? "否" : "是" }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="detail-page">
        <div
         class=""
         v-if="pageIndex != 0"
         @click="checkPage(0)"
        >上一页</div>
        <div
          class=""
          v-if="this.data && pageIndex != (this.data.length - 1)"
          @click="checkPage(1)"
        >下一页</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getReportDetail } from "@/api/water/report";
const columns = [
  {
    title: "序号",
    dataIndex: "name",
    key: "name",
    width: 120
  },
  {
    title: "检测项目／单位",
    dataIndex: "address",
    key: "address",
    width: 90
  },
  {
    title: "检测结果",
    dataIndex: "num",
    key: "num 1",
    width: 103
  },
  {
    title: "是否达标（是／否）",
    dataIndex: "num1",
    key: "num1",
    width: 90
  }
];

export default {
  data() {
    return {
      qualified_arr:[1,0],
      id:0,
      sampleName:"",
      sampleDate:"",
      data:[],
      currentData:[],
      list: [
        // {
        //   key: "1",
        //   name: "11",
        //   address: "4",
        //   num: "29",
        // },
        // {
        //   key: "2",
        //   name: "22",
        //   address: "ee",
        //   num: "dd",
        // }
      ],
      columns,
      page:["上一页","下一页"],
      pageIndex:0,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    this.getDetail();
  },
  mounted() {},
  methods:{
    getDetail(){
      getReportDetail({
        id:this.id
      }).then(res => {
        if (res.code == 0) {
          this.sampleDate = res.data.sampleDate;
          this.sampleName = res.data.sampleName;
          this.data = res.data.reportSampleEntities;
          if (this.data) {
            this.currentData = this.data[this.pageIndex];
            this.list = this.currentData?.reportDataEntities;
          }
        }
      })
    },
    // 上一页下一页
    checkPage(index){
      if (index == 1) {
        // 下一页
        this.pageIndex += 1;
        this.currentData = this.data[this.pageIndex];
        this.list = this.currentData?.reportDataEntities;
      } else {
        // 上一页
        this.pageIndex -= 1;
        this.currentData = this.data[this.pageIndex];
        this.list = this.currentData?.reportDataEntities;
      }
      // this.pageIndex = index;
    },
    onChange(value){
      console.log(value)
      if (value.length > 0) {
        let list = this.data[this.pageIndex]?.reportDataEntities;
        let list_data = [];
        for(let i in list) {
          console.log(list[i].qualified)
          if (value.indexOf(list[i].qualified) != -1) {
            list_data.push(list[i]);
          }
        }
        console.log(list_data)
        this.list = list_data;
      } else {
        this.list = [];
      }

    },
    back() {
      // this.$router.go(-1);
      this.$router.push({
        path:'/index/waterQuality/index/testReport'
      })
    },
  }
};
</script>

<style scoped lang="less">
.detail {
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 30px;
  box-sizing: border-box;
}
.detail-con {
  width: 100%;
  min-height: 860px;
  padding: 30px 20px;
  box-sizing: border-box;
  background: #ffffff;
}
.detail-back {
  width: 80px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  margin-bottom: 21px;
  cursor: pointer;
}
.detail-back-img {
  width: 16px;
  height: 17px;
  margin-right: 8px;
}
.detail-back-text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.detail-table {
  // padding: 52px 0px 0px 0px;
  width: 100%;
  // height: 700px;
  overflow: auto;
  margin-top: 20px;
}
.detail-table-content {
  width: 100%;
  border-top: 1px solid #e1e1ee;
  border-left: 1px solid #e1e1ee;
}
.detail-table-content-top {
}
.table-active {
  top: -32px;
  left: 30px;
  width: 20px;
  height: 20px;
}
.table-activeB {
  top: -32px;
  left: 47%;
  width: 20px;
  height: 20px;
}
.width200 {
  width: 200px;
}
.width90 {
  width: 94px;
}
.width100 {
  width: 100px;
}
.width120 {
  width: 120px;
  height: 60px;
}
.width300{
  width: 25%;
  height: 60px;
}
.detail-table-content-itemA {
  height: 60px;
  background: #fafafa;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  // padding: 0px 10px;
  padding: 0px 8px;

  box-sizing: border-box;
}
.detail-table-content-itemA-con {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #28282c;
  line-height: 18px;
}
.detail-table-content-itemB {
  height: 120px;
  background: #fafafa;
  box-sizing: border-box;
}
.detail-table-content-itemB-top {
  height: 50px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #28282c;
  line-height: 50px;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  text-align: center;
}
.detail-table-content-itemB-bottom-item {
  width: 80px;
  height: 70px;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
}
.detail-table-content-itemB-bottom-item-text {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
  line-height: 16px;
}
.detail-table-content-list-item {
  height: 40px;
  text-align: center;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
}

.detail-table-content-title{
  width: 100%;
  height: 100px;
  background-color: rgba(67, 152, 236, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  font-size: 18px;
}
.titleselect{
  display: flex;
  align-items: center;
}
.detail-page{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;
  div{
    width: 108px;
    height: 50px;
    line-height: 50px;
    background-color: rgba(255, 255, 255, 100);
    font-size: 14px;
    text-align: center;
    border: 1px solid rgba(217, 217, 217, 100);
    cursor: pointer;
    margin-right: 10px;
  }
  .progress-tabone-select{
    color: rgba(24, 144, 255, 100);
    border: 1px solid rgba(24, 144, 255, 100);
  }
}
</style>
